@use 'src/CustomTheme';

.passwordInput {
  border: none;
  border-bottom: 1px solid CustomTheme.$primary;
  padding: 5px 10px;
  outline: none;
  border-radius: 0;
}

.inputIcon {
  background-color: white;
  border: none;
  border-bottom: 1px solid CustomTheme.$primary;
  outline: none;
  border-radius: 0;
}

.innerContainer {
  background-color: white;
  min-height: 36rem;
  img {
    max-width: 30rem;
    width: 100%;
  }
}

.mainContainer {
  background-color: #212b39;
}

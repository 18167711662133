$headerHeight: 3em;

%fullHeight {
  height: calc(var(--vh, 1vh) * 100);
  max-height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  overflow: hidden;
  overflow-y: scroll !important;
}
%fullHeightWithHeader {
  height: calc(var(--vh, 1vh) * 100 - $headerHeight);
  max-height: calc(var(--vh, 1vh) * 100 - $headerHeight);
  overflow: hidden;
  overflow-y: scroll !important;
}

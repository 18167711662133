@import url('https://fonts.googleapis.com/css?family=Montserrat');

$primary: #212b39;

@import '~bootstrap/scss/bootstrap.scss';

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}

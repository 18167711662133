.carets {
  color: lightgray;
}

.table-header:hover .carets {
  color: black;
}

.table-header {
  cursor: pointer;
}

.small-cell {
  width: 1px;
  white-space: nowrap;
}

@use 'src/CustomTheme';
@use '../variables';

.shellContainer {
  @extend %fullHeight;
}

.mainContainer {
  @extend %fullHeightWithHeader;
}

.navbar {
  height: variables.$headerHeight;
  width: 100%;
  flex-wrap: nowrap !important;
  z-index: 4;
  background-color: CustomTheme.$primary;
}

.navbtn {
  border-color: transparent;
  width: max-content;
}

.offcanvas {
  background-color: CustomTheme.$primary !important;
  color: white !important;
}

.selectorDropDown {
  border: none;
  border-bottom: 1px solid black !important;
  button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-width: 12rem;
    border: none;
    color: black;
    background-color: transparent;
    font-family: sans-serif;
    font-size: 16px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.085);
      border: none;
    }
  }
}

.selectorDropDownMenu {
  min-width: 18rem;
  max-height: 15rem;
  overflow: hidden;
  overflow-y: scroll;
}

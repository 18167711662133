.label {
  font-weight: bold;
}

.info {
  cursor: help;
  color: lightgray;
}

.input-readonly {
  cursor: default;
  color: grey !important;

  &:focus,
  &:active {
    box-shadow: none !important;
  }
}
